// Home.js

import React from 'react';
import './App.css';
import './Home.css';
const Home = () => {
  return (

    <div >
      <h1>Welcome to my website!</h1>
      <p>My name is Joe Pehlke and I am a software engineer based in San Diego currently looking for my first industry role!
      I have a passion for full stack development, cybersecurity, DevOps, and data engineering. 
      Please explore my blog and get in touch with me through the contact page!
      </p>
      
      
    </div>
    
    
  );
};

export default Home;
