import React from 'react'

const Contact = () => {
  return(
    <div>
      <h1>contact me</h1>
    </div>
  );
};

export default Contact;